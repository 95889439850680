import * as React from 'react'
import { Routes, Route } from 'react-router-dom'
import FirstPhase from './FirstPhase'
import SecondPhase from './SecondPhase.js'

function App() {
  return (
    <Routes>
      <Route path='venue/:regid' element={<SecondPhase />} />
      <Route path='start/:campaign' element={<FirstPhase />} />
    </Routes>
  )
}

export default App
