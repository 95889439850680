import * as React from 'react'
import PropTypes from 'prop-types'
import { styled } from '@mui/material/styles'
import Stack from '@mui/material/Stack'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import Check from '@mui/icons-material/Check'
import SettingsIcon from '@mui/icons-material/Settings'
import FileCopyIcon from '@mui/icons-material/FileCopy'
import { useParams } from 'react-router'
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector'
import { v4 as uuidv4 } from 'uuid'
import {
  Container,
  Box,
  Button,
  Typography,
  TextField,
  Grid,
  // InputAdornment,
  Paper,
  Card,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  // CardHeader,
  CardContent,
  CardActions,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  FormControlLabel,
  FormGroup,
  Checkbox,
} from '@mui/material'
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions'
import ReceiptIcon from '@mui/icons-material/Receipt'
import { initializeApp } from 'firebase/app'
import { ref, getDatabase } from 'firebase/database'
import { getFunctions } from 'firebase/functions'
import { useHttpsCallable } from 'react-firebase-hooks/functions'
import { useObject } from 'react-firebase-hooks/database'

const firebaseApp = initializeApp({
  apiKey: 'AIzaSyAKEkR-_UEvCegyK9TVEhEpC-vV9vY0OWY',
  authDomain: 'falcon-testing-e32f1.firebaseapp.com',
  databaseURL: 'https://falcon-testing-e32f1-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'falcon-testing-e32f1',
  storageBucket: 'falcon-testing-e32f1.appspot.com',
  messagingSenderId: '793846617202',
  appId: '1:793846617202:web:48eecac5477d81a6f9deeb',
  measurementId: 'G-HWG8YX397T',
})
const database = getDatabase(firebaseApp)

const INTEGRATIONS = [
  {
    id: '01-lights',
    category: 'Valot',
    
    integrations: [{ name: 'Intesis', cost: 50, desc: 'Jos sinulla on asennettuna Intesis Box valojenhallinta, niin järjestelmä kytkeytyy siihen.', logo: '' }],
  },
  { id: '02-accesscontrol', category: 'Kulunvalvonta', integrations: [{ name: 'Tolotrack', desc: 'Yhteys Tolotrack järjestelmään', cost: 50, logo: '' }, { name: 'Optima', desc: 'Yhteys Optima järjestelmään', cost: 50, logo: '' }] },

  {
    id: '03-paymentgateway',
    category: 'Online maksut',
    integrations: [
      { name: 'Stripe', cost: 0, logo: '' },
      { name: 'Paytrail', cost: 70, logo: '' },
      { name: 'ePassi', cost: 50, logo: '' },
      { name: 'Smartum', cost: 50, logo: '' },
      { name: 'Easybreak', cost: 50, logo: '' },
    ],
  },
  { id: '04-cashier', category: 'Kassa', integrations: [{ name: 'Odoo / Bonware', desc: 'Varausjärjestelmä juttelee Bonwaren kassan kanssa jolloin kassasta voi kuitata vuorojen maksut.', cost: 80, logo: '' }] },

  { id: '05-accounting', category: 'Taloushallinto', integrations: [{ name: 'Netvisor', desc: 'Myyntilaskujen synkronointi Netvisorin kanssa.', cost: 100, logo: '' }] },

  { id: '06-signature', category: 'Allekirjoitukset', integrations: [{ name: 'Visma Sign', desc: 'Vakiovuorosopimusten allekirjoitukset voidaan kerätä sähköisellä allekirjoituksella.',  cost: 40, logo: '' }] },
  {
    id: '07-marketing',
    category: 'Asiakasviestintä / Markkinointi',
    integrations: [
      { name: 'Mailchimp', desc: 'Jos käytät Mailchimpiä viestintään, niin järjestelmä pitää asiakaslistasi automaattisesti ajantasalla ja lisää Mailchimpiin eri asiakasryhmiä automaattisesti esim. vakiovuorolaiset jne.', cost: 30, logo: '' },
      { name: 'Twilio', desc: 'Voit lähettää järjestelmästä suoraan tekstiviestejä asiakkaille.', cost: 30, logo: '' },
    ],
  },
  {
    id: '08-miscancellous',
    category: 'Muut',
    integrations: [
      { name: 'Tennisclub.fi', desc: 'Tennisseurojen jäsentietojen automaattinen synkronointi.',  cost: 40, logo: '' },
      { name: 'Frank', desc: 'Opiskelija statuksen tiedon automaattinen ylläpito.', cost: 25, logo: '' },
    ],
  },
]

const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
  display: 'flex',
  height: 22,
  alignItems: 'center',
  ...(ownerState.active && {
    color: '#784af4',
  }),
  '& .QontoStepIcon-completedIcon': {
    color: '#784af4',
    zIndex: 1,
    fontSize: 18,
  },
  '& .QontoStepIcon-circle': {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
}))

function QontoStepIcon(props) {
  const { active, completed, className } = props

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? <Check className='QontoStepIcon-completedIcon' /> : <div className='QontoStepIcon-circle' />}
    </QontoStepIconRoot>
  )
}

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
}

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage: 'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage: 'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderRadius: 1,
  },
}))

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
  zIndex: 1,
  color: '#fff',
  width: 50,
  height: 50,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.active && {
    backgroundImage: 'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  }),
  ...(ownerState.completed && {
    backgroundImage: 'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
  }),
}))

function ColorlibStepIcon(props) {
  const { active, completed, className } = props
  const icons = {
    1: <SettingsIcon />,
    2: <FileCopyIcon />,
    3: <IntegrationInstructionsIcon />,
    4: <ReceiptIcon />,
  }

  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  )
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
}

const steps = ['Resurssit, hinnoittelu ja laskutus', 'Siirrettävät tiedot', 'Lisäosat / Yhteydet muihin järjestelmiin', 'Hinta']

// function toSeoUrl(url) {
//   return url
//     .toString() // Convert to string
//     .normalize('NFD') // Change diacritics
//     .replace(/[\u0300-\u036f]/g, '') // Remove illegal characters
//     .replace(/\s+/g, '-') // Change whitespace to dashes
//     .toLowerCase() // Change to lowercase
//     .replace(/&/g, '-and-') // Replace ampersand
//     .replace(/[^a-z0-9\-]/g, '') // Remove anything that is not a letter, number or dash
//     .replace(/-+/g, '-') // Remove duplicate dashes
//     .replace(/^-*/, '') // Remove starting dashes
//     .replace(/-*$/, '') // Remove trailing dashes
// }

const getBasePrice = (resources, revenue) => {
  const rev = parseInt(revenue)
  const res = parseInt(resources)
  // console.log(rev, res)
  if (rev < 100000) {
    return 100
  } else if (rev < 1100000) {
    const _b = Math.floor((rev * 0.0072) / 12)
    const _r = Math.floor(res / 10) * 15
    // console.log(_b, _r)
    return Math.max(_b + _r, 150)
  } else {
    const _b = Math.floor((rev * 0.0057) / 12)
    const _r = Math.floor(res / 10) * 15
    // console.log(_b, _r)
    return Math.max(_b + _r, 100)
  }
}

const CAMPAIGNS = {
  smyry: { percent: 15, name: 'SMY ry jäsenalennus' },
}

export default function SecondPhase() {
  let { regid } = useParams()
  const [basePrice, setBasePrice] = React.useState(350)
  const [activeStep, setActiveStep] = React.useState(0)
  const [dynamicRegid, setDynamicRegid] = React.useState(null)
  const [skipped, setSkipped] = React.useState(new Set())
  const [values, setValues] = React.useState({
    resources: 1,
    recurrentinvoicing: '',
    recurrentsale: '',
    recurrentpricing: '',
    pricingmodel: '',
    oldsystem: '',
    revenue: 0,
  })
  // const [executeCallable, callloading, callerror] = useHttpsCallable(getFunctions(firebaseApp), 'phasetwo')
  // const [snapshot, loading, error] = useObject(ref(database, '/wizard-registrations/' + regid))
  const [executeCallable] = useHttpsCallable(getFunctions(firebaseApp), 'phasetwo')
  const [snapshot] = useObject(ref(database, '/wizard-registrations/' + regid))
  let predefines = snapshot ? snapshot.val() : regid.startsWith('hinnoittelu11-') ? { adminemail: 'info@cintoia.com' } : null

  if(regid.startsWith('hinnoittelu11-')) regid = regid.split('-')[1]
  if (regid === 'hinnat') {
    if (!dynamicRegid) {
      regid = 'uid-' + uuidv4()
      setDynamicRegid(regid)
    } else {
      regid = dynamicRegid
    }
    predefines =  { adminemail: 'info@cintoia.com' }
  }
  // console.log('regid', regid)
  // React.useEffect(() => {
  //   if (predefines) setValues({ ...values, regid })
  // }, [predefines, values, setValues, regid])

  // React.useEffect(() => {
  //   console.log('useEffect')
  //   setBasePrice(getBasePrice(values.resources, values.revenue))
  // }, [values, setBasePrice])
  const [cart, setCart] = React.useState([])

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value })
    if (
      (prop === 'resources' && values.resources !== event.target.value) ||
      (prop === 'revenue' && values.revenue !== event.target.value)
    ) {
      const res = prop === 'resources' ? event.target.value : values.resources
      const rev = prop === 'revenue' ? event.target.value : values.revenue
      setBasePrice(getBasePrice(res, rev))
    }
  }
  // const isStepOptional = (step) => {
  //   return step === 1
  // }

  const isStepSkipped = (step) => {
    return skipped.has(step)
  }

  const handleNext = async () => {
    let newSkipped = skipped
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values())
      newSkipped.delete(activeStep)
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1)
    setSkipped(newSkipped)
    const data = JSON.parse(JSON.stringify(values))
    data.regid = regid
    data.integrations = cart
    data.baseprice = basePrice

    await executeCallable(data)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  // const handleReset = () => {
  //   setActiveStep(0)
  // }

  const subtotal = () => {
    return cart.map(({ cost }) => cost).reduce((sum, i) => sum + i, basePrice)
  }

  const addCart = (integration) => {
    if (!cart.find((x) => x.name === integration.name)) {
      let _c = [...cart]
      _c.push(integration)
      setCart(_c)
    }
  }

  const removeCart = (integration) => {
    if (cart.find((x) => x.name === integration.name)) {
      let _c = [...cart].filter((x) => x.name !== integration.name)
      setCart(_c)
    }
  }

  const getForm = (step) => {
    if (step === 0) {
      return (
        <Stack direction='column' justifyContent='flex-start' alignItems='center' spacing={2}>
          {!predefines.venuename ?
            <TextField
              fullWidth
              label='Keskuksen / Yhtiön nimi'
              id='venuename'
              size='small'
              onChange={handleChange('venuename')}
              sx={{ mt: 2, maxWidth: '50ch' }}
            /> :
            <Typography>{predefines.venuename}</Typography>
          }
          <TextField
            fullWidth
            label='Varattavien / laskutettavien resurssien määrä'
            id='resources'
            size='small'
            value={values.resources}
            onChange={handleChange('resources')}
            sx={{ maxWidth: '50ch' }}
            type='number'
          />
          <FormControl fullWidth size={'small'} sx={{ maxWidth: '50ch' }}>
            <InputLabel id='revenue'>Kenttiin ja varauksiin liittyvä liikevaihto</InputLabel>
            <Select
              labelId='revenue'
              id='revenue'
              value={values.revenue}
              label='Kenttiin ja varauksiin liittyvä liikevaihto'
              onChange={handleChange('revenue')}
              size={'small'}
            >
              <MenuItem value={0}><em>Valitse liikevaihtoväli</em></MenuItem>
              <MenuItem value={1}>0 - 100 000 €</MenuItem>
              <MenuItem value={100000}>100 000 - 200 000 €</MenuItem>
              <MenuItem value={200000}>200 000 - 300 000 €</MenuItem>
              <MenuItem value={300000}>300 000 - 400 000 €</MenuItem>
              <MenuItem value={400000}>400 000 - 500 000 €</MenuItem>
              <MenuItem value={500000}>500 000 - 700 000 €</MenuItem>
              <MenuItem value={700000}>700 000 - 1 000 000 €</MenuItem>
              <MenuItem value={1000000}>1 000 000 - 1 500 000 €</MenuItem>
              <MenuItem value={1500000}>1 500 000 - 2 500 000 €</MenuItem>
              <MenuItem value={2500000}>yli 2 500 000 €</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth size={'small'} sx={{ maxWidth: '50ch' }}>
            <InputLabel id='recurrent-sale'>Vakiovuorojen sopimukset</InputLabel>
            <Select
              labelId='recurrent-sale'
              id='recurrent-sale'
              value={values.recurrentsale}
              label='Vakiovuorojen sopimukset'
              onChange={handleChange('recurrentsale')}
              size={'small'}
            >
              <MenuItem value={'peryear'}>Uusi sopimus vuosittain</MenuItem>
              <MenuItem value={'ongoing'}>Toistaiseksi voimassa oleva</MenuItem>
            </Select>
          </FormControl>
          <Paper elevation={0} sx={{ textAlign: 'left', p: 1, maxWidth: '50ch' }}>
            <Stack spacing={1}>
              <Typography variant='caption'>
                Toistaiseksi voimassa olevassa voi olla taukoja. Esim. kesäkausi.
              </Typography>
            </Stack>
          </Paper>
          <FormControl fullWidth size={'small'} sx={{ maxWidth: '50ch' }}>
            <InputLabel id='recurrent-invoicing'>Vakiovuorojen laskutus</InputLabel>
            <Select
              labelId='recurrent-invoicing'
              id='recurrent-invoicing'
              value={values.recurrentinvoicing}
              label='Vakiovuorojen laskutus'
              onChange={handleChange('recurrentinvoicing')}
              size={'small'}
            >
              <MenuItem value={'ahead'}>Etukäteen kausi kerrallaan</MenuItem>
              <MenuItem value={'afterwards'}>Jälkikäteen kuukausittain</MenuItem>
              <MenuItem value={'norecurrent'}>Ei vakiovuorolaskutusta</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth size={'small'} sx={{ maxWidth: '50ch' }}>
            <InputLabel id='recurrent-pricing'>Vakiovuorojen hinnoittelu</InputLabel>
            <Select
              labelId='recurrent-pricing'
              id='recurrent-pricing'
              value={values.recurrentpricing}
              label='Vakiovuorojen hinnoittelu'
              onChange={handleChange('recurrentpricing')}
              size={'small'}
            >
              <MenuItem value={'contract'}>Sopimuksen mukaan</MenuItem>
              <MenuItem value={'currentpricelist'}>Voimassaolevan hinnaston mukaan</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth size={'small'} sx={{ maxWidth: '50ch' }}>
            <InputLabel id='pricing-model'>Irtovuorojen hinnoittelu</InputLabel>
            <Select
              labelId='pricing-model'
              id='pricing-model'
              value={values.pricingmodel}
              label='Irtovuorojen hinnoittelu'
              onChange={handleChange('pricingmodel')}
              size={'small'}
            >
              <MenuItem value={'startinghour'}>Alkavan tunnin mukaan</MenuItem>
              <MenuItem value={'presenthour'}>Kuluvan tunnin mukaan</MenuItem>
            </Select>
          </FormControl>
          <Paper elevation={0} sx={{ textAlign: 'left', p: 1, maxWidth: '50ch' }}>
            <Stack spacing={1}>
              <Typography variant='caption'>
                Alkava tunti: Hinnastosta valitaan koko tunnille hinta joka on voimassa kun tunti alkaa.
              </Typography>
              <Typography variant='caption'>
                Kuluva tunti: Hinnastosta valitaan kuluvan hetken hinta. Esimerkki. Hinnasto A 10:00-16:30 22€, B
                16:30-19:00 36 €. Varaus on 16:00-17:00. 16:00-16:30 hinta 11€ ja 16:30-17:00 18€ Yhteensä 29€.
              </Typography>
            </Stack>
          </Paper>
        </Stack>
      )
    } else if (step === 1) {
      return (
        <Stack direction='column' justifyContent='flex-start' alignItems='center' spacing={2}>
          <FormControl fullWidth size={'small'} sx={{ maxWidth: '50ch' }}>
            <InputLabel id='recurrent-sale'>Oletko siirtymässä toisesta järjestelmästä?</InputLabel>
            <Select
              labelId='recurrent-sale'
              id='recurrent-sale'
              value={values.oldsystem}
              label='Oletko siirtymässä toisesta järjestelmästä?'
              onChange={handleChange('oldsystem')}
              size={'small'}
            >
              <MenuItem value={'no'}>Minulla ei ole aiempaa järjestelmää</MenuItem>
              <MenuItem value={'sl'}>SL Systems Varausjärjestelmä</MenuItem>
              <MenuItem value={'play'}>Play.fi</MenuItem>
              <MenuItem value={'enkore'}>Enkore</MenuItem>
              <MenuItem value={'playtomic'}>Playtomic</MenuItem>
            </Select>
          </FormControl>
          {values.oldsystem !== 'no' ? (
            <React.Fragment>
              <Typography variant='body2'>Mitä tietoja siirretään?</Typography>
              <FormGroup>
                <FormControlLabel control={<Checkbox />} label='Vakiovuorot' />
                <FormControlLabel control={<Checkbox />} label='Saldot' />
              </FormGroup>
            </React.Fragment>
          ) : null}
        </Stack>
      )
    } else if (step === 2) {
      return (
        <Stack spacing={2} direction='column' justifyContent='flex-start' alignItems='center'>
          {INTEGRATIONS.map((group) => {
            const products = group.integrations.map((integration) => {
              const inCart = cart.find((x) => x.name === integration.name)
              if (!inCart && integration.cost === 0) addCart(integration)
              const bgColor = inCart || integration.cost === 0 ? '#8fee87' : 'white'
              return (
                <Card sx={{ minWidth: '20ch', maxWidth: '30ch', backgroundColor: bgColor }}>
                  <CardContent>
                    <Typography variant='subtitle2'>{group.category}</Typography>
                    <Typography gutterBottom variant='h5' component='div'>
                      {integration.name}
                    </Typography>
                    <Typography gutterBottom variant='caption' component='div'>
                      {integration.desc}
                    </Typography>
                    <Typography variant='body2' color='text.secondary'>
                      {integration.cost} € / kk
                    </Typography>
                  </CardContent>
                  <CardActions>
                    {inCart ? (
                      <Button size='small' onClick={() => removeCart(integration)}>
                        Poista
                      </Button>
                    ) : (
                      <Button size='small' onClick={() => addCart(integration)}>
                        {integration.cost > 0 ? 'Lisää' : ''}{' '}
                      </Button>
                    )}
                  </CardActions>
                </Card>
              )
            })
            return (
              <Grid container spacing={2}>
                {products.map((p) => (
                  <Grid item>{p}</Grid>
                ))}
              </Grid>
            )
          })}
        </Stack>
      )
    } else if (step === 3) {
      let discount = null
      if (predefines && predefines.campaign) {
        discount = CAMPAIGNS[predefines.campaign]
      }

      return (
        <Stack spacing={2} direction='column' justifyContent='flex-start' alignItems='center'>
          <TableContainer component={Paper} sx={{ maxWidth: '100ch' }}>
            <Table sx={{ minWidth: 650 }} aria-label='simple table'>
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell align='right'>Hinta</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow key={'base'} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component='th' scope='row'>
                    Perusmaksu
                  </TableCell>
                  <TableCell align='right'>{basePrice} € / kk</TableCell>
                </TableRow>

                {cart.map((row) => (
                  <TableRow key={row.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell component='th' scope='row'>
                      {row.name}
                    </TableCell>
                    <TableCell align='right'>{row.cost} € / kk</TableCell>
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell colSpan={0}>
                    <b>Yhteensä alv 0%</b>
                  </TableCell>
                  <TableCell align='right'>
                    <b>{subtotal()} € / kk</b>
                  </TableCell>
                </TableRow>
                {discount && discount.percent > 0 ? (
                  <TableRow>
                    <TableCell colSpan={0}>
                      <b>
                        {discount.name} {discount.percent} % - ensimmäinen vuosi
                      </b>
                    </TableCell>
                    <TableCell align='right'>
                      <b>{Math.floor(subtotal() * ((100 - discount.percent) / 100))} € / kk</b>
                    </TableCell>
                  </TableRow>
                ) : null}
              </TableBody>
            </Table>
          </TableContainer>
          <Paper elevation={0} sx={{ textAlign: 'left', p: 1, maxWidth: '50ch' }}>
            <Stack spacing={1}>
              {/* <Typography variant='body2'>
                Voit luoda tunnuksen antamillasi tiedoilla. Tunnus on ilmainen ja on voimassa 2 viikkoa. Se ei sido eikä
                velvoita sinua mihinkään. Tunnus on toimiva, voit tehdä sinne oikeat konfiguraatiot ja voit jatkaa sen
                käyttöä sopimuksen mukaan. Olemme sinuun yhteydessä testijakson aikana.
              </Typography> */}
            </Stack>
          </Paper>
        </Stack>
      )
    } else {
      return null
    }
  }

  if (predefines) {
    return (
      <Container>
        <Box>
          <Stack sx={{ width: '100%' }} spacing={4}>
          <React.Fragment>
                <Stack>
                <Paper elevation={0} spacing={2} sx={{ textAlign: 'center', mt: 2 }}>
                  <div>
                  <img src='https://www.cintoia.com/img/logo.png' alt='C' style={{ height: 70, verticalAlign: 'middle' }} />
                    <span style={{
                      fontFamily: "Poppins",
                      marginLeft: 5,
                      fontWeight: 400,
                      fontSize: 25,
                      verticalAlign: 'middle'
                    }}>CINTOIA</span>
                    </div>
                  </Paper>
                </Stack>
              </React.Fragment>
            <Stepper alternativeLabel activeStep={0} connector={<ColorlibConnector />}>
              {steps.map((label, index) => {
                const stepProps = {}
                stepProps.completed = activeStep > index
                stepProps.active = activeStep === index
                return (
                  <Step key={label} {...stepProps}>
                    <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
                  </Step>
                )
              })}
            </Stepper>
            {activeStep === steps.length ? (
              <React.Fragment>
                <Stack>
                  <Paper elevation={0} spacing={2} sx={{ textAlign: 'center' }}>
                    <Typography variant='body1'>
                      Kiitos tiedoista. Olemme yhteydessä sinuun muutaman päivän sisällä.
                    </Typography>
                  </Paper>
                </Stack>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Box>
                  {getForm(activeStep)}
                  <Stack direction={'row'} sx={{ mt: 2 }}>
                    {activeStep > 1 ? (
                      <Button color='inherit' disabled={activeStep === 0} onClick={handleBack} sx={{ mr: 1 }}>
                        Edellinen
                      </Button>
                    ) : null}
                    <Box sx={{ flex: '1 1 auto' }} />
                    <Button onClick={handleNext}>{activeStep === steps.length - 1 ? 'Valmis' : 'Seuraava'}</Button>
                  </Stack>
                </Box>
              </React.Fragment>
            )}
          </Stack>
        </Box>
      </Container>
    )
  } else {
    return <></>
  }
}
