import * as React from 'react'
import PropTypes from 'prop-types'
import { styled } from '@mui/material/styles'
import Stack from '@mui/material/Stack'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import Check from '@mui/icons-material/Check'
import SettingsIcon from '@mui/icons-material/Settings'
import GroupAddIcon from '@mui/icons-material/GroupAdd'
import VideoLabelIcon from '@mui/icons-material/VideoLabel'
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector'
import { Container, Box, Button, Typography, TextField, InputAdornment, Paper } from '@mui/material'
import NONVALIDDOMAINS from './mailproviders'
import { initializeApp } from 'firebase/app'
import { getFunctions } from 'firebase/functions'
import { useHttpsCallable } from 'react-firebase-hooks/functions'
import { useParams } from 'react-router'

const firebaseApp = initializeApp({
  apiKey: 'AIzaSyAKEkR-_UEvCegyK9TVEhEpC-vV9vY0OWY',
  authDomain: 'falcon-testing-e32f1.firebaseapp.com',
  databaseURL: 'https://falcon-testing-e32f1-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'falcon-testing-e32f1',
  storageBucket: 'falcon-testing-e32f1.appspot.com',
  messagingSenderId: '793846617202',
  appId: '1:793846617202:web:48eecac5477d81a6f9deeb',
  measurementId: 'G-HWG8YX397T',
})

// const QontoConnector = styled(StepConnector)(({ theme }) => ({
//   [`&.${stepConnectorClasses.alternativeLabel}`]: {
//     top: 10,
//     left: 'calc(-50% + 16px)',
//     right: 'calc(50% + 16px)',
//   },
//   [`&.${stepConnectorClasses.active}`]: {
//     [`& .${stepConnectorClasses.line}`]: {
//       borderColor: '#784af4',
//     },
//   },
//   [`&.${stepConnectorClasses.completed}`]: {
//     [`& .${stepConnectorClasses.line}`]: {
//       borderColor: '#784af4',
//     },
//   },
//   [`& .${stepConnectorClasses.line}`]: {
//     borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
//     borderTopWidth: 3,
//     borderRadius: 1,
//   },
// }))

const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
  display: 'flex',
  height: 22,
  alignItems: 'center',
  ...(ownerState.active && {
    color: '#784af4',
  }),
  '& .QontoStepIcon-completedIcon': {
    color: '#784af4',
    zIndex: 1,
    fontSize: 18,
  },
  '& .QontoStepIcon-circle': {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
}))

function QontoStepIcon(props) {
  const { active, completed, className } = props

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? <Check className='QontoStepIcon-completedIcon' /> : <div className='QontoStepIcon-circle' />}
    </QontoStepIconRoot>
  )
}

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
}

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage: 'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage: 'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderRadius: 1,
  },
}))

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
  zIndex: 1,
  color: '#fff',
  width: 50,
  height: 50,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.active && {
    backgroundImage: 'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  }),
  ...(ownerState.completed && {
    backgroundImage: 'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
  }),
}))

function ColorlibStepIcon(props) {
  const { active, completed, className } = props
  const icons = {
    1: <SettingsIcon />,
    2: <GroupAddIcon />,
    3: <VideoLabelIcon />,
  }

  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  )
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
}

const steps = ['Keskuksen tiedot', 'Pääkäyttäjä']

function toSeoUrl(url) {
  return (
    url
      .toString() // Convert to string
      .normalize('NFD') // Change diacritics
      .replace(/[\u0300-\u036f]/g, '') // Remove illegal characters
      .replace(/\s+/g, '-') // Change whitespace to dashes
      .toLowerCase() // Change to lowercase
      .replace(/&/g, '-and-') // Replace ampersand
      // eslint-disable-next-line no-useless-escape
      .replace(/[^a-z0-9\-]/g, '') // Remove anything that is not a letter, number or dash
      .replace(/-+/g, '-') // Remove duplicate dashes
      .replace(/^-*/, '') // Remove starting dashes
      .replace(/-*$/, '')
  ) // Remove trailing dashes
}

const NONVALID_NAMES = ['tennis-oy', 'falcon-oy', 'taitava', 'padel-oy', 'talitaivallahti']

export default function FirstStep() {
  let { campaign } = useParams()
  // const [duplicateCheck, setDuplicateCheck] = React.useState(false)
  const [isTakenDomain, setIsTakenDomain] = React.useState(false)
  const [isDoneEmail, setIsDoneEmail] = React.useState(false)
  const [activeStep, setActiveStep] = React.useState(0)
  const [skipped, setSkipped] = React.useState(new Set())
  const [values, setValues] = React.useState({
    venuename: '',
    urlname: '',
    adminname: '',
    adminemail: '',
    urledited: false,
  })
  // const [executeCallable, loading, error] = useHttpsCallable(getFunctions(firebaseApp), 'phaseone')
  const [executeCallable] = useHttpsCallable(getFunctions(firebaseApp), 'phaseone')
  const [executeHasemaildone] = useHttpsCallable(getFunctions(firebaseApp), 'hasemaildone')

  let emailDomain = values.adminemail.indexOf('@') > 2 ? values.adminemail.split('@')[1] : ''
  console.log('emailDomain', emailDomain)

  const validEmail =
    values.adminemail.length > 10 &&
    values.adminemail.indexOf('@') > 2 &&
    !NONVALIDDOMAINS.NONVALIDDOMAIN.includes(emailDomain) &&
    !isDoneEmail

  const allValid = values.venuename.length > 7 && !NONVALID_NAMES.includes(values.urlname)
  console.log('allValid', allValid)

  const privateEmail = NONVALIDDOMAINS.NONVALIDDOMAIN.includes(emailDomain)

  const handleChange = async (prop, event) => {
    console.log('handleChange', prop)
    if (prop === 'venuename' && !values.urledited) {
      const trimmed = toSeoUrl(event.target.value)
      const isTaken = await isAlreadyTaken(trimmed)
      console.log('isTaken venuename', isTaken)
      setValues({ ...values, [prop]: event.target.value, urlname: trimmed })
      if (isTaken !== isTakenDomain) {
        setIsTakenDomain(isTaken)
      }
    } else if (prop === 'urlname') {
      const trimmed = toSeoUrl(event.target.value)
      const isTaken = await isAlreadyTaken(trimmed)
      console.log('isTaken urlname', isTaken)
      setValues({ ...values, [prop]: trimmed, urledited: true })
      if (isTaken !== isTakenDomain) {
        setIsTakenDomain(isTaken)
      }
    } else if (prop === 'adminemail') {
      setValues({ ...values, [prop]: event.target.value })
      const isDone = await isAlreadyDone(event.target.value)
      console.log('isDone email', isDone)
      if (isDone !== isDoneEmail) {
        setIsDoneEmail(isDone)
      }
    } else {
      setValues({ ...values, [prop]: event.target.value })
    }
  }

  const isAlreadyDone = async (email) => {
    let result = await executeHasemaildone({ email })
    return result.data.result
  }

  // const isAlreadyDone = async () => {
  //   if (!duplicateCheck && values.adminemail) {
  //     let result = await executeHasemaildone({ email: values.adminemail })
  //     console.log('result', result)
  //     setDuplicateCheck(true)
  //     return result.data.result
  //   }
  //   return false
  // }

  const isAlreadyTaken = async (domain) => {
    if (domain) {
      let result = await executeHasemaildone({ domain })
      console.log(result)
      return result.data.result
    }
    return false
  }

  const isStepSkipped = (step) => {
    return skipped.has(step)
  }

  const handleNext = async () => {
    let newSkipped = skipped
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values())
      newSkipped.delete(activeStep)
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1)
    setSkipped(newSkipped)

    console.log('handleNext', activeStep, '===', steps.length - 1)
    if (activeStep === steps.length - 1) {
      const data = JSON.parse(JSON.stringify(values))
      if (campaign) data.campaign = campaign
      await executeCallable(data)
    }
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  // const handleReset = () => {
  //   setActiveStep(0)
  // }

  const getForm = (step) => {
    console.log(values)
    if (step === 0) {
      return (
        <Stack direction='column' justifyContent='flex-start' alignItems='center' spacing={2}>
          <TextField
            error={!allValid}
            fullWidth
            label='Keskuksen / Yhtiön nimi'
            id='venuename'
            size='small'
            onChange={(e) => handleChange('venuename', e)}
            sx={{ mt: 2, maxWidth: '50ch' }}
          />
          <TextField
            error={!allValid || isTakenDomain}
            fullWidth
            label='Testiosoite'
            id='path-adornment'
            value={values.urlname}
            onChange={(e) => handleChange('urlname', e)}
            sx={{ maxWidth: '50ch' }}
            InputProps={{
              startAdornment: <InputAdornment position='start'>https://utest.cintoia.com/</InputAdornment>,
            }}
            size='small'
            helperText={isTakenDomain ? 'Osoite varattu' : ''}
          />
          <TextField
            error={!allValid || isTakenDomain}
            fullWidth
            id='host-adornment'
            label='Tuotanto-osoite'
            sx={{ maxWidth: '50ch' }}
            disabled
            value={values.urlname}
            InputProps={{
              endAdornment: <InputAdornment position='start'>.cintoia.com</InputAdornment>,
            }}
            size='small'
            helperText={isTakenDomain ? 'Osoite varattu' : ''}
          />
        </Stack>
      )
    } else if (step === 1) {
      let errorMessage = ''
      if (privateEmail) {
        errorMessage = 'ddd'
      }
      if (isDoneEmail) {
        errorMessage =
          'Osoitteeseen ' +
          values.adminemail +
          ' on jo aiemmin lähetetty rekisteröitymislinkki. Tarkistathan myös roskapostikansion. Jos et viestiä löydä, niin laita viestiä osoitteeseen info@cintoia.com.'
      }
      return (
        <Stack direction='column' justifyContent='flex-start' alignItems='center' spacing={2}>
          <TextField
            fullWidth
            label='Pääkäyttäjän nimi'
            id='adminname'
            size='small'
            value={values.adminname}
            onChange={(e) => handleChange('adminname', e)}
            sx={{ maxWidth: '50ch' }}
          />
          <TextField
            error={!validEmail}
            fullWidth
            label='Pääkäyttäjän email'
            id='admin'
            size='small'
            value={values.adminemail}
            onChange={(e) => handleChange('adminemail', e)}
            sx={{ maxWidth: '50ch' }}
            helperText={errorMessage}
          />

          <Paper sx={{ textAlign: 'center', maxWidth: '50ch' }} elevation={0}>
            <Typography variant='subtitle1'>Sähköpostiin lähetetään varmennusviesti.</Typography>
            <Typography variant='body1'>
              Varmennettuasi osoitteen pääset valitsemaan hinnastosta palvelun ominaisuudet.
            </Typography>
          </Paper>
        </Stack>
      )
    } else {
      return null
    }
  }

  let allGood = false
  if (activeStep === 0) {
    allGood = allValid
  } else {
    allGood = validEmail && !privateEmail
  }
  if (isTakenDomain) allGood = false
  if (isDoneEmail) allGood = false
  // !allValid : activeStep === 1 ? !validEmail && !privateEmail : false
  console.log('activeStep', activeStep)
  return (
    <Container>
      <Box>
        <Stack sx={{ width: '100%' }} spacing={4}>
          <Stepper alternativeLabel activeStep={0} connector={<ColorlibConnector />}>
            {steps.map((label, index) => {
              const stepProps = {}
              stepProps.completed = activeStep > index
              stepProps.active = activeStep === index
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
                </Step>
              )
            })}
          </Stepper>
          {activeStep === steps.length ? (
            <React.Fragment>
              <Stack>
                <Paper elevation={0} s spacing={2} sx={{ textAlign: 'center' }}>
                  <Typography variant='body1'>
                    Sähköpostiisi {values.adminemail} on lähetetty vahvistuslinkki.
                  </Typography>
                </Paper>
              </Stack>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Box>
                {getForm(activeStep)}
                <Stack direction={'row'} sx={{ mt: 2 }}>
                  {activeStep > 0 ? (
                    <Button color='inherit' disabled={activeStep === 0} onClick={handleBack} sx={{ mr: 1 }}>
                      Edellinen
                    </Button>
                  ) : null}
                  <Box sx={{ flex: '1 1 auto' }} />
                  <Button disabled={!allGood} onClick={handleNext}>
                    {activeStep === steps.length - 1 ? 'Tallenna' : 'Seuraava'}
                  </Button>
                </Stack>
              </Box>
            </React.Fragment>
          )}
        </Stack>
      </Box>
    </Container>
  )
}
